<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-selects="querySelects"
    :query-values="queryValues"
    entity-type="herSweden"
    locale-section="pages.herSweden"
    field-section="herSe"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "HerSwedenDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        fmisId
        kmrId
        parish {
          id
        }
        raaNumber
      `,
      querySelects: /* GraphQL */ `
        parish: herSwedenParishes {
          id
          raaParish
          parishCode
        }
      `,
      inputAttributes: {
        parish: { required: true }
      }
    };
  }
};
</script>
